




















































import { Component, Vue } from "vue-property-decorator";
import * as echarts from "echarts/core";
import { BarChart, LineChart, PieChart } from "echarts/charts";
import {
    GridComponent,
    TitleComponent,
    LegendComponent,
    PolarComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { apiUserAnalysis } from "@/api/data/data";
echarts.use([
    BarChart,
    GridComponent,
    CanvasRenderer,
    TitleComponent,
    LegendComponent,
    PolarComponent,
    LineChart,
    PieChart,
]);
@Component
export default class User extends Vue {
    /** S Data **/
    summary = {
        visit: "",
        visitor: "",
    };

    month = new Date().getFullYear() + "-" + (new Date().getMonth() + 1);

    visitor = {
        tooltip: {
            trigger: "axis",
        },
        legend: {
            data: ["订单金额"],
        },
        xAxis: {
            type: "category",
            data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
            splitLine: {
                show: true, // 是否显示分隔线。默认数值轴显示，类目轴不显示
                interval: "1", // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
            },
        },
        yAxis: {
            type: "value",
            // type: "category"
        },
        series: [
            {
                name: "成交订单",
                type: "line",
                stack: "总量",
                data: [],
            },
        ],
    };

    business = {
        tooltip: {
            trigger: "axis",
        },
        legend: {
            data: ["订单金额"],
        },
        xAxis: {
            type: "category",
            data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
            splitLine: {
                show: true, // 是否显示分隔线。默认数值轴显示，类目轴不显示
                interval: "1", // 坐标轴刻度标签的显示间隔，在类目轴中有效.0显示所有
            },
        },
        yAxis: {
            type: "value",
            // type: "category"
        },
        series: [
            {
                name: "订单金额",
                type: "line",
                stack: "总量",
                data: [],
            },
        ],
    };

    // $refs!: { formRef: any }
    /** E Data **/

    /** S Methods **/

    onReset() {
        this.month = new Date().getFullYear() + "-" + (new Date().getMonth() + 1);
        this.getDataCenterVisit();
    }

    // 获取数据
    getDataCenterVisit() {
        // 清空echarts 数据
        this.business.xAxis.data = [];
        this.visitor.xAxis.data = [];

        apiUserAnalysis({ month: this.month }).then((res) => {

            res.user.list[0].data = res.user.list[0].data.reverse();
            res.new_user.list[0].data = res.new_user.list[0].data.reverse();

            this.summary = res.summary;
            this.business.xAxis.data = res.user.date.reverse();
            this.visitor.xAxis.data = res.new_user.date.reverse();

            res.user.list.forEach((item: any, index: number) => {
                this.business.series[index].data = item.data;
                this.business.series[index].name = item.name;
                this.business.legend.data[index] = item.name;
            });

            res.new_user.list.forEach((item: any, index: number) => {
                this.visitor.series[index].data = item.data;
                this.visitor.series[index].name = item.name;
                this.visitor.legend.data[index] = item.name;
            });
        });
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getDataCenterVisit();
    }
    /** E Life Cycle **/
}
